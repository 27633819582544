<template>
    <v-dialog
        v-model="dialog"
        :persistent="persistent"
        max-width="500"
        @click:outside="onClickOutside"
    >
        <v-card>
            <v-card-title>
                <h3 class="title">{{ title }}</h3>
                <v-spacer></v-spacer>
                <v-btn @click="hide" icon>
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text class="py-4" v-if="content">
                <div>{{ content }}</div>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn small depressed color="secondary" class="black--text normal-btn" @click="onCancel" :disabled="loading">
                    {{ cancelLabel}}
                </v-btn>
                <v-btn :loading="loading" small dark depressed color="primary" @click="onConfirm" class="normal-btn">
                    {{ confirmLabel }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
    name: "KimsaConfirm",
    props: {
        title: {
            type: String,
            default: 'Confirmar',
        },
        content: {
            type: String,
            default: '',
        },
        persistent: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        cancelLabel: {
            type: String,
            default: 'Cancelar',
        },
        confirmLabel: {
            type: String,
            default: 'Confirmar',
        },
        noCloseOnConfirm: {
            type: Boolean,
            default: false,
        },
        noDialogActive: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            dialog: false
        }
    },
    computed: {
        ...mapState(['dialogActive']),
    },
    methods: {
        ...mapMutations(['setDialogActive']),
        show() {
            const vm = this
            vm.dialog = true
            if(!vm.noDialogActive) vm.setDialogActive(true)
        },
        hide() {
            const vm = this
            vm.dialog = false
            if(!vm.noDialogActive) vm.setDialogActive(false)
        },
        onConfirm() {
            const vm = this
            vm.$emit('confirm')
            if(!vm.noCloseOnConfirm) vm.hide()
        },
        onCancel() {
            const vm = this
            vm.$emit('cancel')
            vm.hide()
        },
        onClickOutside() {
            const vm = this
            if(!vm.persistent) return vm.hide()
        }
    },
    watch: {
        dialogActive(val) {
            if (!val && !this.noDialogActive) this.hide()
        },
    }
}
</script>